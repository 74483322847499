import * as React from 'react';
import { Checkbox, Dropdown, Header, Input, Table, List, ListItem } from 'semantic-ui-react';
import './Info.css';
import { Translation } from 'react-i18next';


interface InfoProps {
    fields: Array<Field>;
    onSave?: Function;
    values: any;
    datagroup?: string;
}

interface InfoState {
    values: any;
}

export interface Field {
    name?: string;
    customType?: "phonenumber" | "daterange" | "list" | "date";
    value: string | number | Array<any> | boolean;
    options?: Array<any> | string;
    props?: any;
    switch?: Array<any>;
    object?: string;
}

class Info extends React.Component<InfoProps, InfoState> {
    constructor(props: InfoProps) {
        super(props);

        this.state = {
            values: props.values
        };
    }

    componentWillReceiveProps(nextProps: InfoProps) {
        this.setState({ values: nextProps.values });
    }

    getInputType = (field: Field) => {
        let type: string = "text";
        switch (typeof field.value) {
            case "number":
                type = "number";
                break;
            case "string":
                type = "text";
                break;
            default:
                type = "text";
                break;
        }
        return type;
    }

    onSaveData = (field: Field, value: any) => {
        let valueToSave = value;
        if (this.props.onSave) {
            let optionText;
            if (typeof field.options !== "undefined") {
                let option;
                if (Array.isArray(field.options)) {
                option = field.options.find((optionItem: any) => {
                    return optionItem.value === valueToSave;
                });
                }
                if (typeof option !== "undefined") {
                    optionText = option.name;
                }
            }
            const fieldName = field.value;
            const partial = typeof field.object !== "undefined";
            const object = partial ? field.object : false;
            this.props.onSave(fieldName, valueToSave, object, partial, field.name, optionText, this.props.datagroup);
        }
    }

    getFieldValue = (field: Field) => {

        if (field.value === undefined) {
            field.value = "";
        }
        let fieldValue = this.state.values[field.value.toString()];

        if (typeof field.object !== "undefined" && !Array.isArray(field.value)) {
            // if there is field.object available, true values is
            fieldValue = this.state.values[field.object];
            if (fieldValue !== null && fieldValue !== undefined && typeof fieldValue !== "undefined" && typeof field.value !== "boolean") {
                fieldValue = fieldValue[field.value];
            } else {
                fieldValue = "";
            }
        }
        if (typeof field.options !== "undefined") {
            let arrayOptions: any = [];
            if (field.options === 'FROM_VALUE') {
                arrayOptions = fieldValue;

            } else if (Array.isArray(field.options)) {
               arrayOptions = field.options.map((v) => {
                return {
                    text: v.name, value: v.value
                };
            });
            }
            const arrayValues = arrayOptions.map((option: any) => {
                return option.value;
            });
            let isValueMissingFromOptions = false;
            if (arrayValues.indexOf(fieldValue) === -1 && fieldValue !== "" && typeof fieldValue !== "undefined") {
                isValueMissingFromOptions = true;
                arrayOptions.push({text: fieldValue, value: fieldValue});
            }

            return (
                <React.Fragment>
                    {this.getFieldHeader(field, isValueMissingFromOptions)}
                    <Translation>
                        { (t: Function) => (
                            <Table.Cell width={10} negative={isValueMissingFromOptions}>
                                <Dropdown
                                    size="small"
                                    selectOnBlur={false}
                                    selection={true}
                                    search={true}
                                    fluid={true}
                                    scrolling={true}
                                    noResultsMessage={t("GENERAL_SEARCH_NO_RESULTS")}
                                    onChange={(e: any, data: any) => {
                                        this.onSaveData(field, data.value);
                                    }}
                                    placeholder={t("CHOOSE")}
                                    value={fieldValue}
                                    options={arrayOptions}
                                    {...field.props}
                                />
                            </Table.Cell>
                        )}
                    </Translation>
                </React.Fragment>
            );
        } else if (typeof field.switch !== "undefined") {
            const label = field.switch.filter(option => option.value === field.value);
            return (
                <React.Fragment>
                    {this.getFieldHeader(field)}
                    <Table.Cell width={10}>
                        <Checkbox
                            toggle={true}
                            defaultChecked={fieldValue ? true : false}
                            onChange={(e: any, data: any) => {
                                this.onSaveData(field, data.checked);
                            }}
                            label={label}
                        />
                    </Table.Cell>
                </React.Fragment>
            );
        } else if (field.customType === "date") {
            const inputType = "date";
            return (
                <React.Fragment>
                    {this.getFieldHeader(field)}
                    <Table.Cell width={10}>
                        <Input
                            type={inputType}
                            className="Info-CompactInput"
                            size="tiny"
                            onChange={(event: any) => {
                                this.setValue(field, event.target.value);
                            }}
                            onBlur={(e: any) => {
                                this.onSaveData(field, e.target.value);
                            }}
                            value={fieldValue ||  ''}
                            fluid={true}
                        />
                    </Table.Cell>
                </React.Fragment>
            );
        } else if (field.customType === 'list') {
            if (typeof fieldValue !== "undefined") {
                const listItems = fieldValue.map((fv: any, index: any) => <ListItem key={index}>{fv}</ListItem>);
                return (
                    <React.Fragment>
                        {this.getFieldHeader(field)}
                        <Table.Cell width={10}>
                           <List divided={true} className="infoList">
                            {listItems}
                           </List>
                        </Table.Cell>
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        {this.getFieldHeader(field)}
                        <Table.Cell width={10}>
                           <List divided={true} className="infoList">
                            <Input
                                type="text"
                                disabled={true}
                                size="tiny"
                                fluid={true}
                            />
                           </List>
                        </Table.Cell>
                    </React.Fragment>
                );
            }
        } else {
            const inputType = this.getInputType(field);
            return (
                <React.Fragment>
                    {this.getFieldHeader(field)}
                    <Table.Cell width={10}>
                        <Input
                            type={inputType}
                            className="Info-CompactInput"
                            size="tiny"
                            onChange={(event: any) => {
                                this.setValue(field, event.target.value);
                            }}
                            onBlur={(e: any) => {
                                this.onSaveData(field, e.target.value);
                            }}
                            value={fieldValue ||  ''}
                            fluid={true}
                        />
                    </Table.Cell>
                </React.Fragment>
            );
        }
    }

    setValue = (field: Field, value: any) => {
        let values = Object.assign({}, this.state.values);
        const newFieldValue = value;

        if (typeof field.object !== "undefined" && !Array.isArray(field.value) && typeof field.value !== "boolean") {
            const newObjectValue = {
                ...values[field.object.toString()],
            };
            newObjectValue[field.value] = newFieldValue;
            values[field.object.toString()] = newObjectValue;
        } else {
            values[field.value.toString()] = value;
        }
        this.setState({ values: values });
    }

    getFieldHeader = (field: any, isNegative: boolean = false) => {
        if (field.name) {
            return (
                <Translation>
                    {(t: Function) => (
                        <Table.Cell width={6} negative={isNegative}>
                            <Header as="h5">
                                {t("assetEditor.assetMeta." + field.name, {
                                    defaultValue: field.name
                                })}
                            </Header>
                        </Table.Cell>
                    )}
                </Translation>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <Table striped={true}>
                <Table.Body>
                    {
                        this.props.fields.map((field, index) => {
                            return (
                                <Table.Row key={index}>
                                    {this.getFieldValue(field)}
                                </Table.Row>
                            );
                        })
                    }
                </Table.Body>
            </Table>
        );
    }
}

export default Info;