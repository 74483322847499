import * as React from 'react';
import { Header, Grid } from 'semantic-ui-react';
import FilterAssets from "./FilterAssets";
import EditorContainer from './containers/EditorContainer';
import { useTranslation } from 'react-i18next';
import { State } from "./reducers/initialState";
import { connect } from 'react-redux';
import { fetchAsset } from './actions/assetActions';
import { activateAsset } from './actions/activeAssetActions';
import MenuDropdown from './components/MenuDropdown';
import ProfileDropdown from './components/ProfileDropdown';
import { logout } from './actions/authActions';

const mapStateToProps = (state: State) => {
    return {
        activeAsset: state.activeAsset
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchAsset: (id: string | null) => {
            if(id !== null){
                dispatch(fetchAsset(id));
            } else {
                dispatch(activateAsset(null));
            }
        },
        logout: () => {
          dispatch(logout());
        }
    }
};

interface AppProps {
  activeAsset: string;
  fetchAsset: Function;
  logout: Function;
}

const App = (props: AppProps) => {
  const { t } = useTranslation();
  return (
      <div className="wrapper">
        <Grid verticalAlign="middle" className="main-topbar">
            <Grid.Row>
              <Grid.Column floated="left" className="main-topbar-page-menu" style={{ borderRight: "1px solid lightgrey", width: "60px" }} verticalAlign="middle">
                <MenuDropdown />
              </Grid.Column>
              <Grid.Column computer={3} floated="left" textAlign="left">
                <Header as="h1" inverted={true}>
                  {t("PAGE_TITLE")}
                  <Header.Subheader>
                    {props.activeAsset && `${t("PAGE_CHOSEN_ASSET")}: ${props.activeAsset}`}
                    {!props.activeAsset && `${t("PAGE_NO_CHOSEN_ASSET")}`}
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column computer={8} tablet={4} mobile={1} />
              <Grid.Column computer={4} floated="right" textAlign="right">
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column computer={12} verticalAlign="middle" style={{ color: "white" }}>
                      {t('MAIN_TOPBAR_WELCOME_TEXT')}<br />
                      {t('MAIN_TOPBAR_TO_ASSET_MANAGEMENT')}
                    </Grid.Column>
                    <Grid.Column computer={4}>
                      <ProfileDropdown onLogout={props.logout} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div className="main">
          <div className="sideBar">
            <div style={{marginTop: "10px", padding: "10px"}}>
              <FilterAssets onSelectAsset={props.fetchAsset} />
            </div>
          </div>
          <div className="detailView">
            <div style={{marginTop: "10px", padding: "10px"}}>
              <EditorContainer />
            </div>
          </div>
          </div>
        </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
