import { History } from "history";
import * as React from 'react';
import { Component } from "react";
import { connect } from 'react-redux';
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "react-router-redux";
import LoginContainer from "./containers/LoginContainer";
import Main from "./Main";
import { PrivateRoute } from "./PrivateRoute";

interface AppProps {
  history: History;
  auth: any;
}

class App extends Component<AppProps> {
  render() {
    return (
      <ConnectedRouter history={this.props.history}>
        <div>
          <Route path="/login" component={LoginContainer} />
          <Switch>
            <PrivateRoute path="/" authenticated={this.props.auth} component={Main} />
          </Switch>
        </div>
      </ConnectedRouter>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.loggedIn
  };
};

export default connect(mapStateToProps)(App);
