import * as React from "react";
import { Form, Header, Icon, InputOnChangeData, DropdownProps, DropdownItemProps } from 'semantic-ui-react';
import { Translation } from 'react-i18next';
import Info from "../Info";
import { Component, SyntheticEvent } from 'react';
import moment from 'moment';
import { Field } from '../Info';
import _ from "lodash";
import i18next from "i18next";

interface Asset {
    id: number | null;
    tagId: number | null;
    assetName: string;
    assetNumber: string;
    serialNumber: string;
    costCenter: string;
    profitCenter: string;
    registrationNumber: string | null;
    commissioningDate: number;
    assetMake: string;
    assetModel: string;
    assetMeta: object | null;
    usageMeta: object | null;
    parentAsset: string | null;
    schemaId: number | null;
    assetType: number | null;
    status: string | null;
    organisations: null | number[];
}

interface Schema {
    id: number;
    name: string;
    schema: Field[];
    tagId: number;
}

interface AssetProps {
    asset: Asset
    schemas: Array<any>;
    updateAsset: Function;
    createAsset: Function;
    match: any;
    isNew: boolean;
    organisations: DropdownItemProps[];
    statusOptions: DropdownItemProps[];
}

export const initialAsset: Asset = {
    id: null,
    tagId: null,
    assetName: "",
    assetNumber: "",
    serialNumber: "",
    costCenter: "",
    profitCenter: "",
    registrationNumber: "",
    commissioningDate: 0,
    assetMake: "",
    assetModel: "",
    assetMeta: null,
    usageMeta: null,
    parentAsset: null,
    organisations: null,
    schemaId: null,
    status: null,
    assetType: null
}
class AssetEditor extends Component<AssetProps>{
    state = initialAsset;

    static getDerivedStateFromProps(props: AssetProps, state: Asset) {
        return {
            id: state.id || props.asset.id,
            tagId: state.tagId || props.asset.tagId,
            assetName: state.assetName || props.asset.assetName,
            assetNumber: state.assetNumber || props.asset.assetNumber,
            serialNumber: state.serialNumber || props.asset.serialNumber,
            costCenter: state.costCenter || props.asset.costCenter,
            profitCenter: state.profitCenter || props.asset.profitCenter,
            registrationNumber: state.registrationNumber || props.asset.registrationNumber,
            commissioningDate: state.commissioningDate || props.asset.commissioningDate,
            assetMake: state.assetMake || props.asset.assetMake,
            assetModel: state.assetModel || props.asset.assetModel,
            assetMeta: state.assetMeta || props.asset.assetMeta,
            usageMeta: state.usageMeta || props.asset.usageMeta,
            parentAsset: state.parentAsset || props.asset.parentAsset,
            organisations: state.organisations || props.asset.organisations,
            schemaId: state.schemaId || props.asset.schemaId,
            status: state.status || props.asset.status,
            assetType: state.assetType || props.asset.assetType
        }
    }

    updateAsset = () => {
        this.props.updateAsset(this.props.asset.tagId, this.state)
    }

    createAsset = () => {
        this.props.createAsset(this.state)
    }

    render() {
        const {
            assetName,
            assetNumber,
            serialNumber,
            commissioningDate,
            costCenter,
            profitCenter,
            assetMake,
            assetModel,
            organisations,
            registrationNumber,
            status,
            schemaId
        } = this.state;

        let fields = this.props.schemas.find((schema: Schema) => {
            return schema.id === schemaId;
        });

        if(fields){
            fields = fields.schema;
        } else {
            fields = [];
        }
        let fieldValues = fields.map((field: any) => field.value);
        let nonSchemaFieldNames = Object.keys(this.state.assetMeta || {}).filter(key => !fieldValues.includes(key));
        const nonSchemaFields: Field[] = nonSchemaFieldNames.map(name => {
            let field: Field = {
                name: name,
                value: name
            };
            return field;
        });

        const statusOptions = this.props.statusOptions.map((status: any) => {
            return {
                text: i18next.t("assetEditor.status.values." + status.name),
                value: status.name
            }
        });

        const schemaOptions = this.props.schemas.map((schema: Schema) => {
            return {
                text: schema.name,
                value: schema.id,
                key: `${schema.id}-${schema.name}`
            }
        });

        return (
            <Translation>
                {(t: Function) => (
                    <React.Fragment>
                        <Header as="h2">
                            {`${t("ASSET")}: ${assetName} ${registrationNumber ? `(${registrationNumber})` : ""}`}
                            <Header.Subheader>
                                {this.props.isNew ? t("assetEditor.title_new") : t("assetEditor.title")}
                            </Header.Subheader>
                        </Header>
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Input
                                    required={true}
                                    label={t("assetEditor.assetName.label")}
                                    placeholder={t("assetEditor.assetName.placeholder")}
                                    value={assetName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                        this.setState({assetName: data.value})
                                    }}
                                />
                                <Form.Input
                                    label={t("assetEditor.commissioningDate.label")}
                                    placeholder={t("assetEditor.commissioningDate.placeholder")}
                                    type="date"
                                    value={moment(commissioningDate * 1000).format("YYYY-MM-DD")}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                        this.setState({commissioningDate: moment(data.value).unix()})
                                    }}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Input
                                    label={t("assetEditor.assetNumber.label")}
                                    placeholder={t("assetEditor.assetNumber.placeholder")}
                                    value={assetNumber}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                        this.setState({assetNumber: data.value})
                                    }}
                                />
                                <Form.Input
                                    label={t("assetEditor.serialNumber.label")}
                                    placeholder={t("assetEditor.serialNumber.placeholder")}
                                    value={serialNumber}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                        this.setState({serialNumber: data.value})
                                    }}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Input
                                    label={t("assetEditor.profitCenter.label")}
                                    placeholder={t("assetEditor.profitCenter.placeholder")}
                                    value={profitCenter}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                        this.setState({profitCenter: data.value})
                                    }}
                                />
                                <Form.Input
                                    label={t("assetEditor.costCenter.label")}
                                    placeholder={t("assetEditor.costCenter.placeholder")}
                                    value={costCenter}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                        this.setState({costCenter: data.value})
                                    }}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Input
                                    label={t("assetEditor.assetMake.label")}
                                    placeholder={t("assetEditor.assetMake.placeholder")}
                                    value={assetMake}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                        this.setState({assetMake: data.value})
                                    }}
                                />
                                <Form.Input
                                    label={t("assetEditor.assetModel.label")}
                                    placeholder={t("assetEditor.assetModel.placeholder")}
                                    value={assetModel}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                        this.setState({assetModel: data.value})
                                    }}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Dropdown
                                    required={true}
                                    label={t("assetEditor.assetType.label")}
                                    placeholder={t("assetEditor.assetType.placeholder")}
                                    options={schemaOptions}
                                    value={this.state.schemaId || undefined}
                                    onChange={(event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
                                        const assetType = (this.props.schemas.find(x => x.id === data.value) || {}).tagId || "";
                                        this.setState({
                                            schemaId: data.value,
                                            assetType
                                        });
                                    }}
                                    selectOnBlur={false}
                                    selection={true}
                                    search={true}
                                    minCharacters={3}
                                />
                                <Form.Input
                                    label={t("assetEditor.registrationNumber.label")}
                                    placeholder={t("assetEditor.registrationNumber.placeholder")}
                                    value={registrationNumber}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                        this.setState({registrationNumber: data.value})
                                    }}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Dropdown
                                    required={true}
                                    label={t("assetEditor.organisation.label")}
                                    placeholder={t("assetEditor.organisation.placeholder")}
                                    options={this.props.organisations}
                                    multiple={true}
                                    value={organisations || []}
                                    onChange={(event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
                                        this.setState({organisations: data.value})
                                    }}
                                    selectOnBlur={false}
                                    selection={true}
                                    search={true}
                                    minCharacters={3}
                                />
                                <Form.Dropdown
                                    required={true}
                                    label={t("assetEditor.status.label")}
                                    placeholder={t("assetEditor.status.placeholder")}
                                    options={statusOptions}
                                    value={status || undefined}
                                    onChange={(event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
                                        this.setState({status: data.value})
                                    }}
                                    selectOnBlur={false}
                                    selection={true}
                                />
                            </Form.Group>
                        <Header as="h3">{t("assetEditor.assetMeta.title")}</Header>
                        <Info
                            onSave={(editedField: any, editedValue: any) => {
                                const newAssetMeta = Object.assign({}, this.state.assetMeta);
                                newAssetMeta[editedField] = editedValue;
                                this.setState({assetMeta: newAssetMeta });
                            }}
                            fields={fields}
                            values={this.state.assetMeta || {}}
                        />
                        {!this.props.isNew && (
                            <Form.Button
                                labelPosition="left"
                                icon={true}
                                primary={true}
                                onClick={this.updateAsset}
                            >
                                <Icon name="save" />
                                {t("SAVE")}
                            </Form.Button>
                        )}
                        {this.props.isNew && (
                            <Form.Button
                                labelPosition="left"
                                icon={true}
                                positive={true}
                                onClick={this.createAsset}
                            >
                                <Icon name="plus" />
                                {t("CREATE")}
                            </Form.Button>
                        )}
                        {!this.props.isNew && (
                            <React.Fragment>
                                <Header as="h3">{t("assetEditor.nonSchemaMeta.title")}</Header>
                                <p>{t("assetEditor.nonSchemaMeta.description")}</p>
                                <Info
                                    onSave={(editedField: any, editedValue: any) => {
                                        const newAssetMeta = Object.assign({}, this.state.assetMeta);
                                        newAssetMeta[editedField] = editedValue;
                                        this.setState({assetMeta: newAssetMeta });
                                    }}
                                    fields={nonSchemaFields || []}
                                    values={this.state.assetMeta || {}}
                                />
                            </React.Fragment>
                        )}
                        {!this.props.isNew && (
                            <React.Fragment>
                                <Header as="h3">{t("assetEditor.usageMeta.title")}</Header>
                                <p>{t("assetEditor.usageMeta.description")}</p>
                                <Info
                                    fields={Object.keys(this.state.usageMeta || {}).map((field: string) => {
                                        return {
                                            name: t("assetEditor.usageMeta.fields." + field, field),
                                            value: field
                                        }
                                    })}
                                    values={this.state.usageMeta || {}}
                                />
                            </React.Fragment>
                        )}
                        </Form>
                    </React.Fragment>
                )}
            </Translation>
        )
    }
}

export default AssetEditor;