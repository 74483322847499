import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import ajaxStatusReducer from "./ajaxStatusReducer";
import tagsReducer from './tagsReducer';
import authReducer from './authReducer';
import schemaReducer from './schemaReducer';
import assetReducer from "./assetReducer";
import assetListReducer from './assetListReducer';
import activeAssetReducer from './activeAssetReducer';
import configReducer from './configReducer';
import statusOptionReducer from './statusOptionsReducer';

export default combineReducers(
    {
        ajaxCallsInProgress: ajaxStatusReducer,
        auth: authReducer,
        config: configReducer,
        routing: routerReducer,
        tags: tagsReducer,
        statusOptions: statusOptionReducer,
        assetList: assetListReducer,
        activeAsset: activeAssetReducer,
        assetReducer: assetReducer,
        schemas: schemaReducer
    }
);
