import { FETCH_SCHEMAS_SUCCESS } from '../actions/index';

const initialState = {
  schemas: []
};

const tagsReducer = (state = initialState.schemas, action: any) => {
  switch (action.type) {
    case FETCH_SCHEMAS_SUCCESS: {
      return action.schemas;
    }
    default:
      return state;
  }
};

export default tagsReducer;
