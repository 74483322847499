import { SET_CATEGORIES } from '../actions/index';

const initialState = {
    organisations: "",
    assetTypes: ""
};

const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_CATEGORIES: {
            return {...state, ... {
                organisations: action.organisations,
                assetTypes: action.assetTypes
            }};
        }
        default:
            return state;
    }
};

export default authReducer;
