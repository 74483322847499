import { FETCH_ASSET_SUCCESS } from '../actions/index';
import { FETCH_ASSET_FAILURE } from '../actions/index';
import { UPDATE_ASSET_SUCCESS } from '../actions/index';
import { TOGGLE_SEARCH_DECOMMISSIONED_ASSETS_ONLY_STATE } from '../actions/index';

const initialState: assetInitialState = {
  assets: [],
  searchDecommissionedAssetsOnly: false
};

interface assetInitialState {
  assets: any[];
  searchDecommissionedAssetsOnly: boolean;
}

const assetReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_ASSET_SUCCESS: {
      return {assets: [...state.assets.filter((asset: any) => asset.id !== action.asset.id), Object.assign({}, action.asset)], searchDecommissionedAssetsOnly: state.searchDecommissionedAssetsOnly};
    }
    case FETCH_ASSET_FAILURE: {
      return state;
    }
    case TOGGLE_SEARCH_DECOMMISSIONED_ASSETS_ONLY_STATE: {
      return {searchDecommissionedAssetsOnly: action.param, assets: state.assets};
    }
    case UPDATE_ASSET_SUCCESS: {
      let updatedAsset: any = state.assets.filter((asset: any) => asset.id === action.asset.id);
      if (!updatedAsset) {
        return state;
      }
      updatedAsset = Object.assign({}, updatedAsset[0]);
      return {assets: [...state.assets.filter((asset: any) =>  asset.id !== action.asset.id), Object.assign({}, updatedAsset)], searchDecommissionedAssetsOnly: state.searchDecommissionedAssetsOnly};
    }
    default:
      return state;
  }
};

export default assetReducer;
