import { FETCH_ASSET_STATUSES_SUCCESS } from '../actions/index';

const initialState = {
  statusOptions: []
};

const statusOptionReducer = (state = initialState.statusOptions, action: any) => {
  switch (action.type) {
    case FETCH_ASSET_STATUSES_SUCCESS: {
      return action.statusOptions;
    }
    default:
      return state;
  }
};

export default statusOptionReducer;
