import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AssetEditor from '../components/AssetEditor';
import { updateAsset, createAsset } from '../actions/assetActions';
import { initialAsset } from '../components/AssetEditor';
import { State } from '../reducers/initialState';
import { Tag } from '../Asset';

export interface StatusOption {
    name: string;
}

const mapStateToProps = (state: State, ownProps: any) => {
    let asset = state.assetReducer.assets.find((asset: any) => asset.tagId === state.activeAsset);
    let isNew = false;
    if(!asset){
        asset = initialAsset;
        isNew = true;
    }

    if(asset.organisations){
        asset.organisations = asset.organisations.map((org: string) => {
            if(org.substring(0,3).toUpperCase() === "TAG"){
                return org.substring(3);
            } else {
                return org;
            }
        });
    }

    const { organisations, assetTypes } = state.config;

    return {
        asset: asset,
        schemas: state.schemas,
        isNew,
        statusOptions: state.statusOptions,
        organisations: state.tags
            .filter((tag: Tag) => {
                return tag.categories.includes(organisations);
            })
            .map((tag: Tag) => {
                return {
                    text: tag.name,
                    value: tag.id.substring(3)
                };
            }),
        assetTypes: state.tags
            .filter((tag: Tag) => {
                return tag.categories.includes(assetTypes);
            })
            .map((tag: Tag) => {
                return {
                    text: tag.name,
                    value: tag.id.substring(3)
                };
            }),
        key: asset.tagId,
        ...ownProps
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateAsset: (id: number, newData: object) => { dispatch(updateAsset(id, newData)); },
        createAsset: (newData: object) => { dispatch(createAsset(newData)); }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetEditor));
