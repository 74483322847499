import axios from "axios";

let API_BASE_URL = "";


export default class ApiConfig {
  CONFIG: object = {};

  getConfig() {
    return axios
      .get("/config/config.json");
  }

  setApiUrl(apiUrl: string) {
    API_BASE_URL = apiUrl;
  }

  static getBaseUrl() {
    return API_BASE_URL;
  }
}
