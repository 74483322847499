import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import AssetsApi from '../api/AssetsApi';
import {
    FETCH_ASSET_SUCCESS, FETCH_ASSET_FAILURE,
    FETCH_ASSETLIST_SUCCESS, FETCH_ASSETLIST_FAILURE,
    UPDATE_ASSET_SUCCESS, UPDATE_ASSET_FAILURE,
    CREATE_ASSET_SUCCESS, CREATE_ASSET_FAILURE,
    TOGGLE_SEARCH_DECOMMISSIONED_ASSETS_ONLY_STATE
} from './index';
import { activateAsset } from './activeAssetActions';
import i18n from '../i18n';
import iziToast from "izitoast";

export const fetchAssetSuccess = (asset: any) => {
    return { type: FETCH_ASSET_SUCCESS, asset};
};

export const fetchAssetFailure = (error: Error) => {
    return { type: FETCH_ASSET_FAILURE, error };
};

export const fetchAssetListSuccess = (assetList: any) => {
    return { type: FETCH_ASSETLIST_SUCCESS, assetList };
};

export const fetchAssetListFailure = (assetList: any) => {
    return { type: FETCH_ASSETLIST_FAILURE, assetList };
};

export const updateAssetSuccess = (asset: any) => {
    return { type: UPDATE_ASSET_SUCCESS, asset };
};

export const updateAssetFailure = (asset: any) => {
    return { type: UPDATE_ASSET_FAILURE, asset };
};

export const createAssetSuccess = (asset: any) => {
    return { type: CREATE_ASSET_SUCCESS, asset };
};

export const createAssetFailure = (asset: any) => {
    return { type: CREATE_ASSET_FAILURE, asset };
};

export const toggleSearchDecommissionedAssetsOnlyState = (param: boolean) => {
    return { type: TOGGLE_SEARCH_DECOMMISSIONED_ASSETS_ONLY_STATE, param };
};

export const fetchAsset: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (id: string) => {
    return (dispatch: any) => {
        return AssetsApi.getAsset(id)
            .then((asset: object) => {
                dispatch(fetchAssetSuccess(asset));
                dispatch(activateAsset(id));
            })
            .catch((error: Error) => {
                console.error("error while fetching asset", error);
                dispatch(fetchAssetFailure(error));
            });
    };
};

export const updateAsset:  ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (id: string, newData: object) => {
    return (dispatch: Dispatch) => {
        return AssetsApi.updateAsset(id, newData)
            .then((status: any) => {
                dispatch(updateAssetSuccess(status));
                iziToast.success({
                    message: i18n.t("ASSET_UPDATE_SUCCESSFUL"),
                    position: "bottomRight",
                    icon: "check"
                });
            })
            .catch((error: Error) => {
                console.error("Error while updating an asset", error);
                dispatch(updateAssetFailure(error));
            })
    }
};

export const createAsset: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (newData: object) => {
    return (dispatch: Dispatch) => {
        return AssetsApi.createAsset(newData)
            .then((data: any) => {
                dispatch(createAssetSuccess(data));
                dispatch(fetchAsset(data.tagId) as any);
                iziToast.success({
                    message: i18n.t("ASSET_CREATE_SUCCESSFUL"),
                    position: "bottomRight",
                    icon: "check"
                });
            })
            .catch((error: Error) => {
                console.error("Error while creating an asset", error);
                dispatch(createAssetFailure(error));
                iziToast.error({
                    message: i18n.t("ASSET_CREATE_FAILURE"),
                    position: "bottomRight",
                    icon: "remove"
                });
            })
    }
}

export const fetchAssetList: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (searchDecommissionedAssetsOnly = false) => {
    return (dispatch: Dispatch) => {
        return AssetsApi.getAssetList(searchDecommissionedAssetsOnly)
            .then((assets: any) => {
                dispatch(fetchAssetListSuccess(assets));
                dispatch(toggleSearchDecommissionedAssetsOnlyState(searchDecommissionedAssetsOnly));
            })
            .catch((error: Error) => {
                dispatch(fetchAssetListFailure(error));
                dispatch(toggleSearchDecommissionedAssetsOnlyState(searchDecommissionedAssetsOnly));
            });
    };
};