import axios from "axios";
import ApiConfig from "./ApiConfig";
const API_URL_ASSET_BASE = "/assets/";
const API_URL_ASSETLIST = "/assets";

export default class TicketsApi {
  static getAssetList = (searchDecomAssetsOnly: boolean): Promise<Array<any>> => {
    let query = [] as any;
    if (searchDecomAssetsOnly === true) {
      query.push("decommissioned");
    } else {
      query.push("inUse");
    }
      query = query.map((tag: string) => {
        return "status[]=" + tag;
      })
      .join('&');
    return axios.get(ApiConfig.getBaseUrl() + API_URL_ASSETLIST + `?${query}`).then(response => {
      return response.data;
    });
  }
  static getAsset = (id: string): Promise<any> => {
    return axios.get(ApiConfig.getBaseUrl() + API_URL_ASSET_BASE + id).then(response => {
      return response.data;
    });
  }
  static updateAsset = (id: string, newData: object): Promise<any> => {
    return axios.put(ApiConfig.getBaseUrl() + API_URL_ASSET_BASE + id, newData).then(response => {
      return response.data;
    });
  }
  static createAsset = (newData: object): Promise<any> => {
    return axios.post(ApiConfig.getBaseUrl() + API_URL_ASSET_BASE, newData).then(response => {
      return response.data;
    })
  }
}
