import { connect } from "react-redux";
import LoginComponent from "../components/LoginComponent";
import { login } from '../actions/authActions';

const mapStateToProps = (state: any) => {
  return {
    isUserAuthenticated: state.auth.loggedIn,
    hasEnteredIncorrectLoginCredentials: state.auth.hasEnteredIncorrectLoginCredentials
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {logUserIn: (user: string, password: string) => dispatch(login(user, password))};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
