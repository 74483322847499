import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import TagsApi from '../api/TagsApi';
import { FETCH_TAGS_SUCCESS, FETCH_TAGS_FAILURE } from './index';

export const loadTagsSuccess = (tags: any) => {
    return { type: FETCH_TAGS_SUCCESS, tags };
};
export const loadTagsFailure = (tags: any) => {
    return { type: FETCH_TAGS_FAILURE, tags };
};

export const fetchTags: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
    return (dispatch: Dispatch) => {
        return TagsApi.getTags()
            .then((tags: any) => {
                dispatch(loadTagsSuccess(tags));
            })
            .catch((error: Error) => {
                dispatch(loadTagsFailure(error));
            });
    };
};