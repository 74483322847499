import { FETCH_ASSETLIST_SUCCESS, FETCH_ASSETLIST_FAILURE } from '../actions/index';

const initialState = {
  assetList: []
};

const assetListReducer = (state = initialState.assetList, action: any) => {
    switch (action.type) {
        case FETCH_ASSETLIST_SUCCESS: {
            return action.assetList;
        }
        case FETCH_ASSETLIST_FAILURE: {
            return state
        }
        default: {
            return state;
        }
    }
};

export default assetListReducer;
