import * as React from 'react';
import { List, Icon, Popup, Dropdown } from 'semantic-ui-react';
import { Translation, getI18n } from 'react-i18next';

interface DropdownProps {
    onLogout: Function;
}

interface DropdownState {
    language: any;
}

class ProfileDropdown extends React.Component<DropdownProps, DropdownState> {
    state: DropdownState;
    trigger: React.ReactNode;

    constructor(props: any) {
        super(props);

        let lang = "fi";
        this.state = {
            language: lang,
        };
        getI18n().changeLanguage(lang);
        this.trigger = (
            <Icon size="big" name="cogs" className="profile-dropdown-icon" style={{color: 'white', marginTop: '6px'}} />
        );
    }

    changeLanguage = (i18nInstance: any, value: any) => {
        this.setState({language: value.value});
        let languageCode = value.value;
        switch(value.value){
            case "gb":
                languageCode = "en"
            default:
                break;
        }
        i18nInstance.changeLanguage(languageCode);
    }
    render() {
        return(
            <React.Fragment>
                <Translation ns="translations">
                    {(t: any, { i18n }) => (
                            <Popup style={{minWidth: '200px'}} on="click" position="bottom right" trigger={this.trigger}>
                                <Popup.Content>
                                    <List size="medium">
                                        <List.Item as="a">
                                            <i className={`${this.state.language} flag`} />
                                            <Dropdown
                                                selectOnBlur={false}
                                                value={this.state.language}
                                                onChange={(e, v) => this.changeLanguage(i18n, v)}
                                                options={
                                                    [
                                                        {key: "fi", value: "fi", flag: "fi", text: "Suomi"},
                                                        {key: "gb", value: "gb", flag: "gb", text: "English"}
                                                    ]
                                                }
                                            />
                                        </List.Item>
                                        <List.Item>
                                            <Icon name="hashtag" /> {t("VERSION")} {process.env.REACT_APP_CONTAINER_IMAGE}
                                        </List.Item>
                                        <List.Item as="a" onClick={() => this.props.onLogout()}>
                                            <Icon name="log out" /> {t("MAIN_TOPBAR_LOGOUT")}
                                        </List.Item>
                                    </List>
                                </Popup.Content>
                            </Popup>
                        )}
                </Translation>
            </React.Fragment>
        );
    }
}

export default ProfileDropdown;