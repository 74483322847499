import * as React from 'react';
import { Form, DropdownProps, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { useState } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { fetchAssetList } from './actions/assetActions';

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        tags: state.tags,
        assetList: state.assetList,
        searchDecommissionedAssetsOnly: state.assetReducer.searchDecommissionedAssetsOnly,
        ...ownProps
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {searchAssets: (searchDecommissionedAssets = false) => {dispatch(fetchAssetList(searchDecommissionedAssets))}};
};

const firstCategory = "CAT86";
const secondCategory = "CAT87";

const getTagOptionsByCategory = (tags: any[], category: string) => {
    return tags.filter((tag: any) => {
        return tag.categories.includes(category);
    }).map((tag: any) => {
        return {
            text: tag.name,
            key: `${tag.name}-${tag.id}`,
            value: tag.id
        };
    });
}

interface FilterAssetProps {
    tags: string[];
    assetList: any[];
    onSelectAsset: Function;
    searchAssets: Function;
    searchDecommissionedAssetsOnly: boolean;
}

const FilterAssets = (props: FilterAssetProps) => {
    const organisations = getTagOptionsByCategory(props.tags, firstCategory);
    const assetTypes = getTagOptionsByCategory(props.tags, secondCategory);

    const [selectedOrganisation, setOrganisation] = useState("");
    const [selectedAssetType, setAssetType] = useState("");

    const options = props.assetList.filter((asset: any) => {
        // if selected organisation can be found under asset
        // if selected assetType can be found under asset
        const isOrganisationSelected = selectedOrganisation !== "";
        const isAssetTypeSelected = selectedAssetType !== "";

        const matchesOrganisation = isOrganisationSelected ? (asset.organisations.includes(selectedOrganisation)) : true;
        const matchesAssetType = isAssetTypeSelected ? (asset.assetType.includes(selectedAssetType)) : true;

        if(!matchesOrganisation || !matchesAssetType){
            return false;
        } else {
            return true;
        }
    }).map((asset: any) => {
        return {
            text: asset.registrationNumber !== "" ? `${asset.assetName} (${asset.registrationNumber})` : asset.assetName,
            key: `${asset.name}-${asset.id}`,
            value: asset.tagId
        };
    });

    return(
        <Form>
            <Checkbox style={{marginBottom: "10px", marginLeft: "5px"}} checked={props.searchDecommissionedAssetsOnly} onClick={() => {
                props.searchAssets(props.searchDecommissionedAssetsOnly ? false : true)}
                } label={"Hae arkistoituja kalustoja"}></Checkbox>
            <Form.Dropdown
                label="Organisaatio"
                placeholder="kirjoita etsiäksesi tai valitse"
                value={selectedOrganisation}
                onChange={(e: any, data: any) => setOrganisation(data.value)}
                options={organisations}
                search={true}
                selection={true}
                clearable={true}
                selectOnBlur={false}
                minCharacters={2}
            />
            <Form.Dropdown
                label="Kalustotyyppi"
                placeholder="kirjoita etsiäksesi tai valitse"
                value={selectedAssetType}
                onChange={(e: any, data: any) => setAssetType(data.value)}
                options={assetTypes}
                search={true}
                selection={true}
                clearable={true}
                selectOnBlur={false}
                minCharacters={2}
            />
            <Form.Dropdown
                options={options}
                label="Kalusto"
                placeholder="kirjoita etsiäksesi tai valitse"
                search={true}
                fluid={true}
                onChange={(e: any, data: DropdownProps) => props.onSelectAsset(data.value || null)}
                selection={true}
                minCharacters={3}
                selectOnBlur={false}
                clearable={true}
            />
        </Form>
    )
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(FilterAssets));
