import * as React from "react";
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ( {component: Component, authenticated, ...rest}: any ) => {
        return (
            <Route
                {...rest}
                render={(props: any) => {
                    return authenticated
                        ? <Component {...props}/>
                        : <Redirect to="/login" />;
                }}
            />
        );
};
