export const START_AJAX_CALL = "START_AJAX_CALL";

export const FETCH_TAGS = "FETCH_TAGS";
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";
export const FETCH_TAGS_FAILURE = "FETCH_TAGS_FAILURE";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE_CREDENTIALS = "LOGIN_FAILURE_CREDENTIALS";
export const LOGIN_FAILURE_GENERAL = "LOGIN_FAILURE_GENERAL";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const FETCH_ASSET_SUCCESS = "FETCH_ASSET_SUCCESS";
export const FETCH_ASSET_FAILURE = "FETCH_ASSET_FAILURE";

export const FETCH_ASSETLIST_SUCCESS = "FETCH_ASSETLIST_SUCCESS";
export const FETCH_ASSETLIST_FAILURE = "FETCH_ASSETLIST_FAILURE";

export const UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS";
export const UPDATE_ASSET_FAILURE = "UPDATE_ASSET_FAILURE";

export const CREATE_ASSET_SUCCESS = "CREATE_ASSET_SUCCESS";
export const CREATE_ASSET_FAILURE = "CREATE_ASSET_FAILURE";

export const FETCH_SCHEMAS_SUCCESS = "FETCH_SCEMAS_SUCCESS";
export const FETCH_SCHEMAS_FAILURE = "FETCH_SCHEMAS_FAILURE";

export const ACTIVATE_ASSET_SUCCESS = "ACTIVATE_ASSET_SUCCESS";
export const ACTIVATE_ASSET_FAILURE = "ACTIVATE_ASSET_FAILURE";

export const FETCH_ASSET_STATUSES_SUCCESS = "FETCH_ASSET_STATUSES_SUCCESS";
export const FETCH_ASSET_STATUSES_FAILURE = "FETCH_ASSET_STATUSES_FAILURE";

export const TOGGLE_SEARCH_DECOMMISSIONED_ASSETS_ONLY_STATE = "TOGGLE_SEARCH_DECOMMISSIONED_ASSETS_ONLY_STATE";

export const SET_CATEGORIES = "SET_CATEGORIES";