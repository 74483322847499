import { Cookies } from 'react-cookie';
import { LOGIN_SUCCESS, LOGIN_FAILURE_CREDENTIALS, LOGIN_FAILURE_GENERAL, LOGOUT_SUCCESS, LOGOUT_FAILURE } from '../actions/index';

const cookies = new Cookies();

const initialState = {
    loggedIn: cookies.get("loggedIn") ? true : false,
    hasEnteredIncorrectLoginCredentials: false,
    hasEncounteredGeneralLoginError: false
};

const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOGIN_SUCCESS: {
            return {...state, ...{loggedIn: true, loginName: action.payload}};
        }
        case LOGIN_FAILURE_CREDENTIALS: {
            return {...state, ...{loggedIn: false, hasEnteredIncorrectLoginCredentials: true}};
        }
        case LOGIN_FAILURE_GENERAL: {
            return {...state, ...{loggedIn: false, hasEncounteredGeneralLoginError: true}};
        }
        case LOGOUT_SUCCESS: {
            return {...state, ...{loggedIn: false}};
        }
        case LOGOUT_FAILURE: {
            return {...state, ...{loggedIn: true}};
        }
        default:
            return state;
    }
};

export default authReducer;
