import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import SchemaApi from '../api/SchemaApi';
import {
    FETCH_SCHEMAS_SUCCESS, FETCH_SCHEMAS_FAILURE,
    FETCH_ASSET_STATUSES_SUCCESS, FETCH_ASSET_STATUSES_FAILURE
} from './index';

export const fetchSchemasSuccess = (schemas: any) => {
    return { type: FETCH_SCHEMAS_SUCCESS, schemas };
};
export const fetchSchemasFailure = (tags: any) => {
    return { type: FETCH_SCHEMAS_FAILURE, tags };
};

export const fetchAssetStatusesSuccess = (statusOptions: string[]) => {
    return { type: FETCH_ASSET_STATUSES_SUCCESS, statusOptions };
};

export const fetchAssetStatusesFailure = (error: Error) => {
    return { type: FETCH_ASSET_STATUSES_FAILURE, error };
};

export const fetchSchemas: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
    return (dispatch: Dispatch) => {
        return SchemaApi.getSchemas()
            .then((tags: any) => {
                dispatch(fetchSchemasSuccess(tags));
            })
            .catch((error: Error) => {
                dispatch(fetchSchemasFailure(error));
            });
    };
};

export const fetchStatusOptions: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
    return (dispatch: Dispatch) => {
        return SchemaApi.getAssetStatusOptions()
            .then((statuses: string[]) => {
                dispatch(fetchAssetStatusesSuccess(statuses));
            })
            .catch((error: Error) => {
                console.error("Error while fetching asset status options", error);
                dispatch(fetchAssetStatusesFailure(error));
            })
    }
};
