import { ACTIVATE_ASSET_SUCCESS, ACTIVATE_ASSET_FAILURE } from './index';
import { history } from '../index';
import { ActionCreator, Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Dispatch } from 'redux';

export const activateAssetSuccess = (id: string) => {
    return { type: ACTIVATE_ASSET_SUCCESS, payload: { id }};
};

export const activateAssetFailure = () => {
    return { type: ACTIVATE_ASSET_FAILURE };
};

export const activateAsset: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (id: string | null) => {
    return (dispatch: Dispatch) => {
        return new Promise((resolve: Function) => {
            if(id !== null){
                dispatch(activateAssetSuccess(id));
                history.push("/assets/" + id);
            } else {
                dispatch(activateAssetFailure());
                history.push("");
            }
        });
    };
};