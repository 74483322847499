import * as React from "react";

export type LoginFunction = (user: string, pasword: string) => void;

export interface LoginProps {
  logUserIn: LoginFunction;
  isUserAuthenticated: boolean;
  hasEnteredIncorrectLoginCredentials: boolean;
}

const LoginComponent = (props: LoginProps) => {
  window.location = 'https://transval-cs.eee.do' as any;
  return <div>redirecting...</div>;
}

export default LoginComponent;
