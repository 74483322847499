import axios from "axios";
import ApiConfig from "./ApiConfig";
const API_URL_SCHEMAS = "/schemas/";
const API_URL_STATUSES = "/statusOptions";

export default class SchemasApi {
  static getSchemas = (): Promise<Array<any>> => {
    return axios.get(ApiConfig.getBaseUrl() + API_URL_SCHEMAS).then(response => {
      return response.data;
    });
  }
  static getAssetStatusOptions = (): Promise<string[]> => {
    return axios.get(ApiConfig.getBaseUrl() + API_URL_STATUSES).then(response => {
      return response.data;
    });
  }
}
