import axios from 'axios';
import ApiConfig from './ApiConfig';
const API_URL_LOGIN = "/login";
const API_URL_LOGOUT = "/logout";
import iziToast from 'izitoast';
import i18n from '../i18n';
import { Cookies } from 'react-cookie';

const t = i18n.t.bind(i18n);
const cookies = new Cookies();

export default class AuthApi {
  static login = (user: string, password: string): Promise<any> => {
    return axios
      .post(ApiConfig.getBaseUrl() + API_URL_LOGIN, {
        username: user,
        password: password
      })
      .then(response => {
        localStorage.setItem("token", response.data);
        cookies.set("eeedo", response.data, {
          domain: '.eee.do',
          sameSite: false,
          expires: new Date(Date.now() + 900000)
        });
        axios.defaults.headers = {"Authorization": "Bearer " + localStorage.getItem("token")};
        return {
          hasLoggedIn: true,
          hasEnteredIncorrectLoginCredentials: false,
          hasEncounteredGeneralLoginError: false,
        };
      })
      .catch(error => {
        if (error.response) {
          // server responsed with non 2xx code
          switch (error.response.status) {
            case 400:
              iziToast.error({
                title: t("LOGIN_FAILURE"),
                message: t("LOGIN_CHECK_YOUR_CREDENTIALS"),
                position: "center",
                timeout: 5000,
                icon: "delete"
              });
              return {
                hasLoggedIn: false,
                hasEnteredIncorrectLoginCredentials: true,
                hasEncounteredGeneralLoginError: false,
              };
            default:
              iziToast.error({
                title: t("LOGIN_FAILURE"),
                message: t("LOGIN_TRY_AGAIN_SOON"),
                position: "center",
                timeout: 5000,
                icon: "delete"
              });
              return {
                hasLoggedIn: false,
                hasEnteredIncorrectLoginCredentials: false,
                hasEncounteredGeneralLoginError: true,
              };
          }
        } else if (error.request) {
          iziToast.error({
            title: t("LOGIN_FAILURE"),
            message: t("LOGIN_CHECK_YOUR_INTERNET_CONNECTION"),
            position: "center",
            timeout: 5000,
            icon: "delete"
          });
          return {
            hasLoggedIn: false,
            hasEnteredIncorrectLoginCredentials: false,
            hasEncounteredGeneralLoginError: true,
          };
        } else {
          iziToast.error({
            title: t("LOGIN_FAILURE"),
            message: t("LOGIN_TRY_AGAIN_SOON"),
            position: "center",
            timeout: 5000,
            icon: "delete"
          });
          return {
            hasLoggedIn: false,
            hasEnteredIncorrectLoginCredentials: false,
            hasEncounteredGeneralLoginError: true,
          };
        }
      });
  }

static logout = () => {
    return axios
        .post(ApiConfig.getBaseUrl() + API_URL_LOGOUT)
        .then(() => {
            iziToast.success({
                message: t("LOGOUT_SUCCESSFUL"),
                position: "center",
                icon: "check"
            });
            localStorage.removeItem("token");
            return {
                hasLoggedIn: false
            };
            })
        .catch(() => {
            iziToast.success({
                message: t("LOGOUT_FAILURE"),
                position: "center",
                icon: "delete"
            });
            return {
                hasLoggedIn: true
            };
        });
    }
}
