import axios from "axios";
import ApiConfig from "./ApiConfig";
const API_URL_TAGS = "/tags/";

export default class TagsApi {
  static getTags = (): Promise<Array<any>> => {
    return axios.get(ApiConfig.getBaseUrl() + API_URL_TAGS).then(response => {
      return response.data;
    });
  }
}
