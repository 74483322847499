import { FETCH_TAGS_SUCCESS } from '../actions/index';

const initialState = {
  tags: []
};

const tagsReducer = (state = initialState.tags, action: any) => {
  switch (action.type) {
    case FETCH_TAGS_SUCCESS: {
      return action.tags;
    }
    default:
      return state;
  }
};

export default tagsReducer;
