import './i18n';
import createHistory from "history/createBrowserHistory";
import axios from "axios";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, createStore, compose } from 'redux';
import thunk, { ThunkMiddleware } from "redux-thunk";
import 'semantic-ui-css/semantic.min.css';
import App from "./App";
import ApiConfig from "./api/ApiConfig";
import './index.css';
import reducers from "./reducers";
import { State } from "./reducers/initialState";
import { loginSuccess } from './actions/authActions';
import './App.css';
import _ from "lodash";
import { fetchTags } from './actions/tagActions';
import { fetchAssetList } from './actions/assetActions';
import { fetchSchemas, fetchStatusOptions } from './actions/schemaActions';
import { setCategoriesSuccess } from './actions/initialActions';

type Actions = { type: "FOO" } | { type: "BAR"; result: number };

export const history = createHistory();

let config = new ApiConfig();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(thunk as ThunkMiddleware<State, Actions>, routerMiddleware(history)))
);

const renderApp = (storeObj: any, historyObj: any) => {
  setTimeout(() => {
    ReactDOM.render(
      <Provider store={storeObj}>
        <App history={historyObj} />
      </Provider>,
      document.getElementById("root")
    );
  }, 250);
  /* For some reason, if we render application without setting a timeout, app will crash because
    i18n is not initialized yet. I have no idea how to fix this. */
};

const NOT_ACCEPTABLE = 401;
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status && error.response.status === NOT_ACCEPTABLE) {
        window.location = "https://transval-cs.eee.do/login" as any;
      }

      return Promise.reject(error);
  }
);

config
  .getConfig()
  .then((response: any) => {
    config.setApiUrl(response.data.API_URL);
    const state = store.getState() as any;
    store.dispatch(setCategoriesSuccess(response.data));
    if (state.auth.loggedIn) {
      // is logged in, get users, tags and personaldata
      axios.defaults.headers = {"Authorization": "Bearer " + localStorage.getItem("token")};

      store.dispatch(loginSuccess());
      store.dispatch(fetchTags() as any);
      store.dispatch(fetchSchemas() as any);
      store.dispatch(fetchStatusOptions() as any);
      store.dispatch(fetchAssetList() as any);
      renderApp(store, history);
    } else {
      renderApp(store, history);
    }
  })
  .catch((error: any) => {
    // load component to display config not being loaded and refresh page..
  });
