import { ACTIVATE_ASSET_SUCCESS, ACTIVATE_ASSET_FAILURE } from '../actions/index';

const initialState = {
  activeAsset: null
};

const activeAssetReducer = (state = initialState.activeAsset, action: any) => {
    switch (action.type) {
        case ACTIVATE_ASSET_SUCCESS: {
            return action.payload.id;
        }
        case ACTIVATE_ASSET_FAILURE: {
            return null;
        }
        default:
            return state;
    }
};

export default activeAssetReducer;
