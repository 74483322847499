import { ActionCreator, AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import AuthApi from "../api/AuthApi";
import { LOGIN_SUCCESS, LOGIN_FAILURE_CREDENTIALS, LOGIN_FAILURE_GENERAL, LOGOUT_SUCCESS, LOGOUT_FAILURE } from "./index";
import { fetchTags } from "./tagActions";
import { fetchSchemas, fetchStatusOptions } from "./schemaActions";
import { fetchAssetList } from './assetActions';
import { Cookies } from 'react-cookie';

export interface AuthAction extends AnyAction {
  type: string;
  payload: any;
}
export const loginSuccess = () => {
  return { type: LOGIN_SUCCESS };
};

export const loginFailureCredentials = () => {
  return { type: LOGIN_FAILURE_CREDENTIALS };
};

export const loginFailureGeneral = () => {
  return { type: LOGIN_FAILURE_GENERAL };
};

export const logoutSuccess = () => {
  return { type: LOGOUT_SUCCESS };
};

export const logoutFailure = () => {
  return { type: LOGOUT_FAILURE };
};

export const login: ActionCreator<ThunkAction<any, any, any, any>> = (user: string, password: string) => {
  return (dispatch: Dispatch) => {
    return AuthApi.login(user, password)
      .then((response: any) => {
        if (response.hasLoggedIn) {
          dispatch(loginSuccess());
          dispatch(fetchTags() as any);
          dispatch(fetchSchemas() as any);
          dispatch(fetchStatusOptions() as any);
          dispatch(fetchAssetList() as any);
        } else if (response.hasEnteredIncorrectLoginCredentials) {
          dispatch(loginFailureCredentials());
        } else {
          dispatch(loginFailureGeneral());
        }
      })
      .catch((error: Error) => {
        dispatch(loginFailureGeneral());
      });
  };
};

export const logout: ActionCreator<ThunkAction<any, any, any, any>> = () => {
  return (dispatch: Dispatch) => {
    console.error("LOGOUT");
    return AuthApi.logout()
      .then((response: any) => {
        localStorage.removeItem("token");
        const cookies = new Cookies();
        console.error("COOKIES", cookies);
        cookies.remove('eeedo', {sameSite:false, domain: "eee.do"});
        cookies.remove('eeedocore', {sameSite:false, domain: "eee.do"});
        console.error("COOKIES AFTER REMOVES", cookies);
        dispatch(logoutSuccess());
      }).catch((error: Error) => {
        console.error(error);
        dispatch(logoutFailure());
      });
  };
};
