import * as React from 'react';
import { Translation } from "react-i18next";
import { Dropdown, Icon } from 'semantic-ui-react';

interface DropdownProps {
    [x: string]: any;
}

class MenuDropdown extends React.Component<DropdownProps> {
    trigger: JSX.Element;
    constructor(props: any) {
        super(props);

        this.trigger = (<Icon size="big" name="block layout" style={{color: 'white'}} />);
    }

    render() {
        return(
            <Translation ns="translations">
            {(t: any) => (
                <Dropdown selectOnBlur={false} trigger={this.trigger} simple={true} icon={null} style={{margin: 0, padding: 0}}>
                    <Dropdown.Menu>
                    <Dropdown.Item>
                        <Icon name='dropdown' />
                        <span className='text'>Sivuryhmä</span>
                        <Dropdown.Menu>
                        <Dropdown.Item disabled>Sivu 1</Dropdown.Item>
                        <Dropdown.Item disabled>Sivu 2</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Header>
                        <Icon name="help" /> {t("TOPLEFT_LINK_DROPDOWN_NO_PAGES")}
                    </Dropdown.Header>
                    <Dropdown.Item disabled>Käyttöohjeet</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )}
            </Translation>
        );
    }
}

export default MenuDropdown;